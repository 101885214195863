<template>
  <div>

    <!-- 3D   greenball  blueball-->
    <div class="res-open-content pcddopen">
      <div class="r-o-ssg">
        <table class="com-table">
          <tr>
            <th>期数</th>
            <th width="140">时间</th>
            <th colspan="3">开奖号码</th>
            <th>结果</th>
            <th>总和大小</th>
            <th>总和单双</th>
            <th>极值</th>
            <th>形态</th>
          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <template v-for="(code,i) in item.codeArr">
              <td class="td-ball" v-if="i<3"  :key="code">

<p :class="'ball b'+i">{{ code }}</p>
</td>
<td class="td-ball" v-if="i==3"  :key="code">

<p :class="pcddColors[Number(code)]+'ball1'">{{ code }}</p>
</td>
            </template>
            

            <td :class="fontColor[item.dx]">{{item.dx}}</td>
            <td :class="fontColor[item.ds]">{{item.ds}}</td>
            <td :class="fontColor[item.jizhi]">{{item.jizhi}}</td>
            <td :class="fontColor[item.xingtai]">{{item.xingtai}}</td>

          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjpcdd",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {};
  },

  components: {},
  created() {},
  computed: {},

  methods: {},
};
</script>
<style  scoped>
.r-o-ssg .com-table tr td.ds_d,
.r-o-ssg .com-table tr td.dx_d {
  color: red;
}
.r-o-ssg .com-table tr td.ds_s,
.r-o-ssg .com-table tr td.dx_x {
  color: #00f;
}
li,
ul {
  list-style: none;
}
</style>